import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { HttpclientService } from '../../application/httpclient/httpclient.service';
import { environment } from '../../../../../src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  MetaTagLoaded: boolean;
  MetaTagData: any;
  envSiteName:any;
  HomeADAfterArticle:SafeResourceUrl;
  url2:string='https://'+environment.siteName+'/HomeADAfterArticle.html';

  constructor(private httpclientService: HttpclientService,private titleService: Title,private metaTagService: Meta,private sanitizer:DomSanitizer)
   { this.envSiteName=environment.siteName; }

  ngOnInit(): void {
    this.AllMetaTagNews();
    this.HomeADAfterArticle=this.sanitizer.bypassSecurityTrustResourceUrl(this.url2);
  }
  AllMetaTagNews(): void {
    if(this.MetaTagLoaded!=true){
    this.MetaTagLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'News-article')
    .subscribe(
      items => {
        this.MetaTagData = items[0];
        this.MetaTagLoaded=true;
        console.log('this.MetaTagData',this.MetaTagData);


          //seo code for tags
          this.titleService.setTitle(this.MetaTagData.PageTitle);

          this.metaTagService.updateTag(
          { name: 'og:description', content: this.MetaTagData.OgDescription}
          );
         

          this.metaTagService.updateTag(
            { name: 'og:title', content: this.MetaTagData.PageTitle}
          );

          this.metaTagService.updateTag(
            { name: 'og:image', content: this.MetaTagData.OgImageURL}
          );

          this.metaTagService.updateTag(
            { name: 'og:image:alt', content: this.MetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:description', content: this.MetaTagData.OgDescription}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:title', content: this.MetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:image', content: this.MetaTagData.OgImageURL}
          );
          //seo code for tags end
        });
      }
  }
}
