<h2 class="text-uppercase mb-0">Events</h2>
<div class="row">
    <div class="col-md-6">
        <h3>Conferences</h3>

        <ul class="nav nav-tabs w-100" id="myTab" role="tablist">
            <li class="nav-item w-50 text-center">
                <a class="nav-link active pl-2 pr-2 text-uppercase text-center confeTab" id="home-tab" data-toggle="tab" href="#conferances-tab1" role="tab" aria-controls="home" aria-selected="true">upcoming conferences</a>
            </li>
            <li class="nav-item w-50 text-center">
                <a class="nav-link pl-md-3 pr-md-4 text-uppercase text-center confeTab" id="profile-tab" data-toggle="tab" href="#conferances-tab2" (click)="pastConfDataFunction()" role="tab" aria-controls="profile" aria-selected="false">past conferences</a>
            </li>

        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active pt-3" id="conferances-tab1" role="tabpanel" aria-labelledby="home-tab">
                <!-- mobile view slider -->
                <div class="col-12 d-block d-md-none p-0">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigUpcomingConf" *ngIf="loadedUpcomingConf">
                        <div ngxSlickItem *ngFor="let upcomingConfData of upcomingConfDatas" class="slide">
                            <a target="_blank" [href]='upcomingConfData.RouteURL'>
                                <img class="img-fluid pb-2 image-hight-events" [src]="upcomingConfData.ImageUrl" alt="event image">
                                <h4 class="text-height-2 card-header-height-2 text-uppercase MainTitle">{{upcomingConfData.Name}}

                                </h4>
                            </a>
                            <p class="para-2 mb-md-0 sponDate">{{upcomingConfData.StartEndDate}} <span *ngIf='upcomingConfData.StartEndDate!="" && upcomingConfData.Country!=""'>|</span> {{upcomingConfData.Country}}
                                <span *ngIf='upcomingConfData.IsSponcered==true'>| <font>Sponsored</font></span>
                            </p>
                            <p class="para-1 text-justify text-height-4 card-header-height-3 DescPara" [innerHTML]="upcomingConfData.Details"></p>
                            <a target="_blank" class="para-2 para-2-c-2 Read_More" [href]="upcomingConfData.RouteURL">Register Now</a>
                        </div>
                    </ngx-slick-carousel>
                </div>
                <!-- mobile view slider end -->
                <div class="row d-none d-md-block fix-min-height-events WrapArt" *ngIf="loadedUpcomingConf">
                    <div class="col-md-12 pb-5" *ngFor="let upcomingConfData of upcomingConfDatas">
                        <!-- <div class="cursor-p" routerLink='events/upcoming-conferences/{{upcomingConfData.RouteURL}}/{{upcomingConfData.EventID}}'> -->
                        <a target="_blank" [href]='upcomingConfData.RouteURL'>
                            <img class="img-fluid pb-2 image-hight-events" [src]="upcomingConfData.ImageUrl" alt="event image">

                            <h4 class="text-height-2 card-header-height-2 text-uppercase MainTitle">{{upcomingConfData.Name}}</h4>
                        </a>
                        <p class="para-2 mb-md-0 sponDate">{{upcomingConfData.StartEndDate}} <span *ngIf='upcomingConfData.StartEndDate!="" && upcomingConfData.Country!=""'>|</span> {{upcomingConfData.Country}} 
                            <span *ngIf='upcomingConfData.IsSponcered==true'>| <font>Sponsored</font></span>
                        </p>
                        <p class="para-1 text-justify text-height-4 card-header-height-3 DescPara" [innerHTML]="upcomingConfData.Details"></p>
                        <a target="_blank" class="para-2 para-2-c-2 Read_More" [href]="upcomingConfData.RouteURL">Register Now</a>
                        <!-- </div> -->
                    </div>
                </div>
                <button class="text-uppercase thm-btn btn-theme-success w-100 pt-3 pb-3 moreUpbtn home_btn" routerLink="events/upcoming-conferences">more upcoming conferences</button>


            </div>
            <div class="tab-pane pt-3 fade" id="conferances-tab2" role="tabpanel" aria-labelledby="profile-tab">
                <!-- mobile view slider -->
                <div class="col-12 d-block d-md-none p-0">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigPastConf" *ngIf="loadedPastConf">
                        <div ngxSlickItem *ngFor="let pastConfData of pastConfDatas" class="slide">
                            <!-- <div class="cursor-p" routerLink='events/past-conferences/{{pastConfData.RouteURL}}/{{pastConfData.EventID}}'> -->
                            <a [href]='pastConfData.RouteURL' target="_blank">
                                <img class="img-fluid pb-2 image-hight-events" [src]="pastConfData.ImageUrl" alt="event image">
                                <h4 class="text-height-2 card-header-height-2 text-uppercase MainTitle">{{pastConfData.Name}}

                                </h4>
                            </a>
                            <p class="para-2 mb-md-0 sponDate">{{pastConfData.StartDate}}<span *ngIf='pastConfData.StartDate!="" && pastConfData.Country!=""'>|</span> {{pastConfData.Country}}
                                <span *ngIf='pastConfData.IsSponcered==true'>| <font>Sponsored</font></span>
                            </p>
                            <p class="para-1 text-justify text-height-4 card-header-height-3 DescPara" [innerHTML]="pastConfData.Details"></p>
                            <a target="_blank" class="para-2 para-2-c-2 Read_More" [href]="pastConfData.RouteURL">Read More</a>
                            <!-- </div> -->
                        </div>
                    </ngx-slick-carousel>
                </div>
                <!-- mobile view slider end -->
                <div class="row d-none d-md-block fix-min-height-events WrapArt" *ngIf="loadedPastConf">
                    <div class="col-md-12 pb-5" *ngFor="let pastConfData of pastConfDatas">
                        <!-- <div class="cursor-p" routerLink='events/past-conferences/{{pastConfData.RouteURL}}/{{pastConfData.EventID}}'> -->
                        <a [href]='pastConfData.RouteURL' target="_blank">
                            <img class="img-fluid pb-2 image-hight-events" [src]="pastConfData.ImageUrl" alt="event image">
                            <h4 class="text-height-2 card-header-height-2 text-uppercase MainTitle">{{pastConfData.Name}}

                            </h4>
                        </a>
                        <p class="para-2 mb-md-0 sponDate">{{pastConfData.StartEndDate}} <span *ngIf='pastConfData.StartEndDate!="" && pastConfData.Country!=""'>|</span> {{pastConfData.Country}}
                            <span *ngIf='pastConfData.IsSponcered==true'>| <font>Sponsored</font></span>
                        </p>
                        <p class="para-1 text-justify text-height-4 card-header-height-3 mb-px-28 DescPara" [innerHTML]="pastConfData.Details"></p>
                        <a target="_blank" class="para-2 para-2-c-2 Read_More" [href]='pastConfData.RouteURL'>Read More</a>   
                        <!-- </div> -->
                    </div>
                </div>
                <button class="text-uppercase thm-btn btn-theme-success w-100 pt-3 pb-3 moreUpbtn home_btn" routerLink="events/past-conferences">more past conferences</button>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <h3 class="mt-md-0">Webinars</h3>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item w-50 text-center">
                <a class="nav-link active pl-5 pr-5 text-uppercase text-center webTab" id="home-tab" data-toggle="tab" href="#webinars-tab1" role="tab" aria-controls="home" aria-selected="true">live webinars</a>
            </li>
            <li class="nav-item w-50 text-center">
                <a class="nav-link pl-3 pr-2 text-uppercase text-center webTab" id="profile-tab" data-toggle="tab" href="#webinars-tab2" (click)="onDemandWebinarDataFunction()" role="tab" aria-controls="profile" aria-selected="false">on-demand webinars</a>
            </li>

        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active pt-3" id="webinars-tab1" role="tabpanel" aria-labelledby="home-tab">
                <!-- mobile view slider -->
                <div class="col-12 d-block d-md-none p-0">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigLiveWebiner" *ngIf="loadedLiveWebiner">
                        <div ngxSlickItem *ngFor="let liveWebinarConfData of liveWebinarConfDatas" class="slide">
                            <div class="cursor-p">
                                <a target="_blank" href="{{liveWebinarConfData.RouteURL}}">
                                    <img class="img-fluid pb-2 image-hight-events" [src]="liveWebinarConfData.ImageUrl" alt="event image">
                                    <h4 class="text-height-2 card-header-height-2 text-uppercase MainTitle">{{liveWebinarConfData.Name}}

                                    </h4>
                                </a>
                                <p class="para-2 mb-2 sponDate">{{liveWebinarConfData.DateWithStartTime}}
                                    <span *ngIf='liveWebinarConfData.IsSponcered==true'>| <font>Sponsored</font></span>
                                </p>
                                <p class="para-1 text-justify text-height-4 card-header-height-3 DescPara" [innerHTML]="liveWebinarConfData.Details"></p>
                                <a target="_blank" class="para-2 para-2-c-2 Read_More" href="{{liveWebinarConfData.RouteURL}}">Register Now</a>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
                <!-- mobile view slider end -->
                <div class="row d-none d-md-block fix-min-height-events WrapArt" *ngIf="loadedLiveWebiner">
                    <div class="col-md-12 pb-5" *ngFor="let liveWebinarConfData of liveWebinarConfDatas">
                        <!-- <div class="cursor-p" routerLink='events/live-webinars/{{liveWebinarConfData.RouteURL}}/{{liveWebinarConfData.EventID}}'> -->
                        <!-- <div class="cursor-p" (click)="redirectLiveWebinar(liveWebinarConfData.RouteURL,liveWebinarConfData.EventID)"> -->
                        <a target="_blank" href="{{liveWebinarConfData.RouteURL}}">
                            <img class="img-fluid pb-2 image-hight-events" [src]="liveWebinarConfData.ImageUrl" alt="event image">
                            <h4 class="text-height-2 card-header-height-2 text-uppercase MainTitle">{{liveWebinarConfData.Name}}

                            </h4>
                        </a>
                        <p class="para-2 mb-2 sponDate">{{liveWebinarConfData.DateWithStartTime}}
                            <span *ngIf='liveWebinarConfData.IsSponcered==true'>| <font>Sponsored</font></span>
                        </p>
                        <p class="para-1 text-justify text-height-4 card-header-height-3 DescPara" [innerHTML]="liveWebinarConfData.Details"></p>
                       <!-- class="para-2 para-2-c-2 " <a target="_blank" routerLink='live-webinars/{{liveWebinarConfData.RouteURL}}'> -->
                         <a target="_blank" class="para-2 para-2-c-2 Read_More" href="{{liveWebinarConfData.RouteURL}}">Register Now</a>
                        <!--</a> -->
                        <!-- </div> -->
                    </div>
                </div>

                <button class="text-uppercase thm-btn btn-theme-success w-100 pt-3 pb-3 WebBtn home_btn" routerLink="events/live-webinars">more live webinars</button>
            </div>
            <div class="tab-pane pt-3 fade" id="webinars-tab2" role="tabpanel" aria-labelledby="profile-tab">
                <!-- mobile view slider -->
                <div class="col-12 d-block d-md-none p-0">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigDemandWebiner" *ngIf="loadedOnDemanadWebiner">
                        <div ngxSlickItem *ngFor="let onDemanadWebinerData of onDemanadWebinerDatas" class="slide">
                            <!-- <div class="cursor-p" (click)="redirect(onDemanadWebinerData.RouteURL,onDemanadWebinerData.EventID)"> -->
                            <a target="_blank" [href]="onDemanadWebinerData.RouteURL">
                                <img class="img-fluid pb-2 image-hight-events" [src]="onDemanadWebinerData.ImageUrl" height="269" alt="event image">
                                <h4 class="text-height-2 card-header-height-2 text-uppercase MainTitle">{{onDemanadWebinerData.Name}}</h4>
                            </a>    
                                <p class="para-2 mb-2 sponDate">{{onDemanadWebinerData.Sponsors}}
                                    <span *ngIf='onDemanadWebinerData.IsSponcered==true'>| <font>Sponsored</font></span>
                                </p>
                            
                            <p class="para-1 text-justify text-height-4 card-header-height-3 DescPara" [innerHTML]="onDemanadWebinerData.Details"></p>
                            <a class="para-2 para-2-c-2 Read_More" target="_blank" [href]="onDemanadWebinerData.RouteURL">Watch Now</a>
                            <!-- </div> -->
                        </div>
                    </ngx-slick-carousel>
                </div>
                <!-- mobile view slider end -->
                <div class="row d-none d-md-block fix-min-height-events WrapArtT" *ngIf="loadedOnDemanadWebiner">
                    <div class="col-md-12 pb-5" *ngFor="let onDemanadWebinerData of onDemanadWebinerDatas">
                        <div class="cursor-p">
                            <!-- <div class="cursor-p" routerLink='{{onDemanadWebinerData.RouteURL}}'>  -->
                            <a [href]="onDemanadWebinerData.RouteURL" target="_blank">
                                <!-- <a target="_blank" routerLink="/{{onDemanadWebinerData.RouteURL}}/{{onDemanadWebinerData.EventID}}"> -->
                                <img class="img-fluid pb-2 image-hight-events" [src]="onDemanadWebinerData.ImageUrl" height="269" alt="event image">
                                <h4 class="text-height-2 card-header-height-2 text-uppercase MainTitle">{{onDemanadWebinerData.Name}}</h4>
                            </a>   
                                <p class="para-2 mb-2">{{onDemanadWebinerData.Sponsors}}
                                    <span *ngIf='onDemanadWebinerData.IsSponcered==true'>| <font>Sponsored</font></span>
                                </p>
                            
                            <p class="para-1 text-justify text-height-4 card-header-height-3 DescPara" [innerHTML]="onDemanadWebinerData.Details"></p>
                            <a [href]="onDemanadWebinerData.RouteURL" target="_blank">
                            <a class="para-2 para-2-c-2 Read_More" [href]="onDemanadWebinerData.RouteURL" target="_blank">Watch Now</a> 
                            </a>
                        </div>
                    </div>
                </div>

                <button class="text-uppercase thm-btn btn-theme-success w-100 pt-3 pb-3 WebBtn home_btn" routerLink='events/on-demand-webinars'>more on-demand webinars</button>
            </div>
        </div>
    </div>
</div>