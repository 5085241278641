import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../application/httpclient/httpclient.service';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../../../../src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  EventNewsDatas: any;
  EventLoaded: boolean;
  pastEventsDatas: any;
  pastEventsLoaded: boolean;
  EventMetaTagLoaded: boolean;
  EventMetaTagData: any;
  envSiteName:any;
  HomeADAfterWhitepaper:any;


  constructor(private httpclientService: HttpclientService,private titleService: Title,private metaTagService: Meta, private sanitizer:DomSanitizer) 
  {this.envSiteName=environment.siteName; }

  
  url1:string='https://'+environment.siteName+'/HomeADAfterWhitepaper.html'

  ngOnInit(): void {
    this.AllMetaTagEvent();
    this.HomeADAfterWhitepaper=this.sanitizer.bypassSecurityTrustResourceUrl(this.url1);
    // this.pastEvents();
  }

  AllMetaTagEvent(): void {
    if(this.EventMetaTagLoaded!=true){
    this.EventMetaTagLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'View-Events')
    .subscribe(
      items => {
        this.EventMetaTagData = items[0];
        this.EventMetaTagLoaded=true;
        console.log('this.EventMetaTagData',this.EventMetaTagData);


          //seo code for tags
          this.titleService.setTitle(this.EventMetaTagData.PageTitle);

          this.metaTagService.updateTag(
          { name: 'og:description', content: this.EventMetaTagData.OgDescription}
          );

          this.metaTagService.updateTag(
            { name: 'og:title', content: this.EventMetaTagData.PageTitle}
          );

          this.metaTagService.updateTag(
            { name: 'og:image', content: this.EventMetaTagData.OgImageURL}
          );

          this.metaTagService.updateTag(
            { name: 'og:image:alt', content: this.EventMetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:description', content: this.EventMetaTagData.OgDescription}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:title', content: this.EventMetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:image', content: this.EventMetaTagData.OgImageURL}
          );
          //seo code for tags end
        });
      }
  }

}
